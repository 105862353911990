import Marquee3k from 'marquee3000'
const burger = document.getElementById('burger')
const navigation = document.getElementById('navigation')

burger.addEventListener('click', () => {
  navigation.classList.toggle('open')
  burger.classList.toggle('open')
})

/* const video = document.getElementById('smiley');
video.play() */



const smiley = document.getElementById('smiley_img')

window.addEventListener('scroll', () => {
  smiley.style = `transform: rotate(${window.scrollY / 4}deg)`
})

Marquee3k.init()